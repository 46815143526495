export default {
  data() {
    return {
      links: [
        {
          title: this.$t("HelpCenter.Buyer.InclusivePricing"),
          link: {
            name: "HelpInformation",
            params: {
              subsection: this.$route.params.subsection,
              type: this.$t("HelpCenter.Buyer.InclusivePricing")
                .replaceAll(" ", "-")
                .toLowerCase(),
            },
          },
        },
        {
          title: this.$t("HelpCenter.Buyer.CertifiedInspection"),
          link: {
            name: "HelpInformation",
            params: {
              subsection: this.$route.params.subsection,
              type: this.$t("HelpCenter.Buyer.CertifiedInspection")
                .replaceAll(" ", "-")
                .toLowerCase(),
            },
          },
        },
        {
          title: this.$t("HelpCenter.Buyer.BuyWithUs"),
          link: {
            name: "HelpInformation",
            params: {
              subsection: this.$route.params.subsection,
              type: this.$t("HelpCenter.Buyer.BuyWithUs")
                .replaceAll(" ", "-")
                .toLowerCase(),
            },
          },
        },
        {
          title: this.$t("Buy.Content.Warranty"),
          link: {
            name: "HelpInformation",
            params: {
              subsection: this.$route.params.subsection,
              type: this.$t("Buy.Content.Warranty").toLowerCase(),
            },
          },
        },
        {
          title: this.$t("Buy.Content.Payment"),
          link: {
            name: "HelpInformation",
            params: {
              subsection: this.$route.params.subsection,
              type: this.$t("Buy.Content.Payment").toLowerCase(),
            },
          },
        },
        {
          title: this.$t("Buy.Content.CustomsClearance"),
          link: {
            name: "HelpInformation",
            params: {
              subsection: this.$route.params.subsection,
              type: this.$t("Buy.Content.CustomsClearance")
                .replaceAll(" ", "-")
                .toLowerCase(),
            },
          },
        },
        {
          title: this.$t("Buy.Content.Delivery"),
          link: {
            name: "HelpInformation",
            params: {
              subsection: this.$route.params.subsection,
              type: this.$t("Buy.Content.Delivery").toLowerCase(),
            },
          },
        },
        {
          title: this.$t("Buy.Content.CustomerServices"),
          link: {
            name: "HelpInformation",
            params: {
              subsection: this.$route.params.subsection,
              type: this.$t("Buy.Content.CustomerServices")
                .replaceAll(" ", "-")
                .toLowerCase(),
            },
          },
        },
      ]
    }
  }
}