<template>
  <div>
    <div class="d-flex justify-content-between align-items-baseline">
      <Title :title="heading"></Title>
      <!-- <a
        v-if="pdfUrl"
        :href="pdfUrl"
        class="text-primary shadow-none px-0 d-inline-flex align-items-center favorites-btn ml-4"
        download
        target="_blank"
      >
        <FilePdfBox :size="28" />
      </a> -->
    </div>
    <SectionTitle :sectionTitle="description" />
  </div>
</template>

<script>
import FilePdfBox from "vue-material-design-icons/FilePdfBox.vue";
import Title from "@/components/global/headings/Title.vue"
import SectionTitle from "@/components/global/headings/SectionTitle.vue"

export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    pdfUrl: {
      type: String,
      required: false,
    },
  },
  components: {
    FilePdfBox,
    Title,
    SectionTitle
  },
};
</script>

<style lang="scss" scoped>
.favorites-btn {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0;
  border: 1px solid #d0d0d0 !important;
  span {
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
}
</style>
