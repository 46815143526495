<template>
  <div>
    <div class="container mt-4 mb-7 mt-md-12 mb-md-15 py-0">
      <b-breadcrumb class="mb-5 mb-md-6 p-0" style="padding:0!important">
        <b-breadcrumb-item :to="{ name: 'HelpCenter' }">
          {{ $t("Footer.HelpCenter") }}
        </b-breadcrumb-item>
        <b-breadcrumb-item
          :to="{
            name: 'HelpCenterSubsection',
            params: {
              subsection: $route.params.subsection,
            },
          }"
          style="text-transform:capitalize"
        >
          {{ $route.params.subsection.replaceAll(" ", "-") }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active class="text-capitalize">{{
          textCleanUp($route.params.type)
        }}</b-breadcrumb-item>
      </b-breadcrumb>
      <div class="d-flex flex-column flex-md-row gap-3">
        <div class="bg-white rounded p-3 p-md-4 px-lg-16 py-lg-10 border-outline-secondary border flex-fill flex-grow-1">
          <HeadingInfo
            style="text-transform: capitalize;"
            :heading="getHeading()"
            :description="getDescription()"
          />
          <div
            class="d-flex flex-column flex-md-row justify-content-between gap-3 mt-5 mt-md-6"
          >
            <div>
              <h2 class="h2-secondary">{{ getSection(0, 1) }}</h2>
              <p>
                {{getSection(1, 1)}}
              </p>
              <h2 class="h2-secondary">{{ getSection(0, 2) }}</h2>
              <p>
                {{getSection(1, 2)}}
              </p>
              <h2 class="h2-secondary">{{ getSection(0, 3)  }}</h2>
              <p class="mb-0">
                {{getSection(1, 3)}}
              </p>
            </div>
            <!-- <div class="rounded p-3 p-md-4" style="min-width:400px;background: #E6F0F9;">
          <h6 class="h2-secondary">
            {{$t('HelpCenter.Buyers.OtherInfo')}}
          </h6>
          <div class="d-flex flex-column gap-1">
            <router-link :to="data.link" v-for="data in links" :key="data.link.params.type" style="text-transform:capitalize">
                {{data.title.toLowerCase()}}
            </router-link>
          </div>
        </div> -->
          </div>
        </div>
        <div
          class="secondary-info rounded p-3 p-md-4 px-lg-16 py-lg-10 border-outline-secondary border bg-white"
        >
          <h6 class="fs-20 fw-600 mb-5 mb-xs-4 mb-md-7 mb-lg-9">
            {{ $t("HelpCenter.Buyers.OtherInfo") }}
          </h6>
          <div class="d-flex flex-column gap-1 gap-md-2">
              <router-link
              :to="data.link"
              v-for="data in links"
              :key="data.link.params.type"
              class="d-flex"
              style="text-transform:capitalize"
              >
                <div class="pr-1">
                  {{ data.title.toLowerCase() }}
                </div>
                <ChevronRight/>
                </router-link>
          </div>
        </div>
      </div>
      <MoreHelp />
    </div>
  </div>
</template>

<script>
import HeadingInfo from "@/components/global/headings/HeadingInfo.vue";
import MoreHelp from "@/components/global/MoreHelp.vue";
import helpBuyersLinks from "@/components/mixins/helpBuyersLinks.js";
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';

export default {
  components: {
    HeadingInfo,
    MoreHelp,
    ChevronRight
  },
  mixins: [helpBuyersLinks],
  methods: {
    isSubsection(subsectionArr) {
      return subsectionArr.includes(this.$route.params.subsection) ? true : false
    },
    getHeading() {
      if (this.isSubsection(["buyers","acheteurs"])) {
        return this.typeIncludes(this.$t("HelpCenter.Buyer.InclusivePricing"))
          ? this.$t("HelpCenter.Buyer.InclusivePricing")
          : this.typeIncludes(this.$t("HelpCenter.Buyer.CertifiedInspection"))
          ? this.$t("HelpCenter.Buyer.CertifiedInspection")
          : this.typeIncludes(this.$t("HelpCenter.Buyer.BuyWithUs"))
          ? this.$t("HelpCenter.Buyer.BuyWithUs")
          : this.typeIncludes(this.$t("Buy.Content.Warranty"))
          ? this.$t("Buy.Content.Warranty").toLowerCase()
          : this.typeIncludes(this.$t("Buy.Content.Payment"))
          ? this.$t("Buy.Content.Payment").toLowerCase()
          : this.typeIncludes(this.$t("Buy.Content.CustomsClearance"))
          ? this.$t("Buy.Content.CustomsClearance").toLowerCase()
          : this.typeIncludes(this.$t("Buy.Content.Delivery"))
          ? this.$t("Buy.Content.Delivery").toLowerCase()
          : this.typeIncludes(this.$t("Buy.Content.CustomerServices"))
          ? this.$t("Buy.Content.CustomerServices").toLowerCase()
          : "not found";
      }
      return "not found";
    },
    getDescription() {
      if (this.isSubsection(["buyers","acheteurs"])) {
        return this.typeIncludes(this.$t("HelpCenter.Buyer.InclusivePricing"))
          ? this.$t("HelpCenter.Buyer.InclusivePricingDesc")
          : this.typeIncludes(this.$t("HelpCenter.Buyer.CertifiedInspection"))
          ? this.$t("HelpCenter.Buyer.CertifiedInspectionDesc")
          : this.typeIncludes(this.$t("HelpCenter.Buyer.BuyWithUs"))
          ? this.$t("HelpCenter.Buyer.BuyWithUsDesc")
          : this.typeIncludes(this.$t("Buy.Content.Warranty"))
          ? this.$t("HelpCenter.Buyer.WarrantyDesc")
          : this.typeIncludes(this.$t("Buy.Content.Payment"))
          ? this.$t("HelpCenter.Buyer.PaymentDesc")
          : this.typeIncludes(this.$t("Buy.Content.CustomsClearance"))
          ? this.$t("HelpCenter.Buyer.CustomsClearanceDesc")
          : this.typeIncludes(this.$t("Buy.Content.Delivery"))
          ? this.$t("HelpCenter.Buyer.DeliveryDesc")
          : this.typeIncludes(this.$t("Buy.Content.CustomerServices"))
          ? this.$t("HelpCenter.Buyer.CustomerServicesDesc")
          : "not found";
      }
      return "not found";
    },
    getSection(type,sectionNumber) {
      //0 = heading, 1 = desc
      const addDesc = type === 1 ? 'Desc' : '';
      if (this.isSubsection(["buyers","acheteurs"])) {
        return this.typeIncludes(this.$t("HelpCenter.Buyer.InclusivePricing"))
          ? this.$t("HelpCenter.Buyer.InclusivePricing.Section" + sectionNumber + addDesc)
          : this.typeIncludes(this.$t("HelpCenter.Buyer.CertifiedInspection"))
          ? this.$t("HelpCenter.Buyer.CertifiedInspection.Section" + sectionNumber + addDesc)
          : this.typeIncludes(this.$t("HelpCenter.Buyer.BuyWithUs"))
          ? this.$t("HelpCenter.Buyer.BuyWithUs.Section" + sectionNumber + addDesc)
          : this.typeIncludes(this.$t("Buy.Content.Warranty"))
          ? this.$t("HelpCenter.Buyer.Warranty.Section" + sectionNumber + addDesc)
          : this.typeIncludes(this.$t("Buy.Content.Payment"))
          ? this.$t("HelpCenter.Buyer.Payment.Section" + sectionNumber + addDesc)
          : this.typeIncludes(this.$t("Buy.Content.CustomsClearance"))
          ? this.$t("HelpCenter.Buyer.CustomsClearance.Section" + sectionNumber + addDesc)
          : this.typeIncludes(this.$t("Buy.Content.Delivery"))
          ? this.$t("HelpCenter.Buyer.Delivery.Section" + sectionNumber + addDesc)
          : this.typeIncludes(this.$t("Buy.Content.CustomerServices"))
          ? this.$t("HelpCenter.Buyer.CustomerServices.Section" + sectionNumber + addDesc)
          : "not found";
      }
      return "not found";
    },
    typeIncludes(text) {
      return this.$route.params.type.includes(
        text.replaceAll(" ", "-").toLowerCase()
      )
        ? true
        : false;
    },
    textCleanUp(text) {
      return text.replaceAll("-", " ");
    },
  },
  beforeMount() {
    document.title = `${this.textCleanUp(this.$route.params.type)} | ${
      document.title
    }`;
  },
};
</script>

<style lang="scss" scoped>
</style>